<template>
  <collLogOff :fieldList="fieldList" :typePage="typePage"></collLogOff>
</template>

<script>
import CollLogOff from "@/views/modules/collection/business/collLogOff.vue";
export default {
    name: "auxiliaryList",
    components:{CollLogOff},
    data(){
        return{
            fieldList:[
                {
                    "archivesBasicTypeMethodCode": "=",
                    "key": "entryState",
                    "keyName": "",
                    "theKey": "",
                    "value": 2
                },

            ],
            typePage:'auxiliaryList',
        }
    },
}
</script>

<style scoped>

</style>